<template>
  <b-flip-modal @close="close(false)">
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="modal-header">
          <span class="com-title">{{ title }}</span>
        </div>
        <div v-if="error" class="error">{{ error }}</div>
        <div class="modal-body user-form">
          <form>
            <div class="form-group">
              <label for="userName">User Name</label>
              <input
                id="userName"
                ref="usernameInput"
                v-model="userData.userName"
                type="text"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="form-group">
              <label for="email">User Email <span class="required">*</span></label>
              <input
                id="email"
                ref="useremailInput"
                v-model="userData.email"
                type="text"
                :disabled="!isNewUser"
                @input="validateEmail"
              />
            </div>
            <div v-if="!isExternalAdmin" class="form-group lg-control-box">
              <label for="orgs">Organization <span class="required">*</span></label>
              <k-select
                ref="organizationIdInput"
                :options="allOrganizations"
                label-key="name"
                :multiple="false"
                :value="userData.organizationId"
                :searchable="true"
                label="Please select Organization"
                :class="[isOrgAlert ? '' : 'errorBox']"
                @input="onSelectOrganization"
              />
            </div>
            <div class="form-group lg-control-box">
              <label for="role">Role <span class="required">*</span></label>
              <k-select
                :options="roleOptions"
                label-key="name"
                :multiple="false"
                :value="changedRoleId"
                :searchable="true"
                label="Please Select Role"
                :class="[isRoleAlert ? '' : 'errorBox']"
                @input="onSelectRole"
              />
            </div>
            <div class="form-group lg-control-box">
              <label for="accounts">
                {{ DisplayAccountTitle }}
                <span v-if="DisplayAccountTitle === 'Managed by Accounts'" class="required">*</span>
              </label>
              <k-select
                ref="userAccInput"
                name="account"
                label-key="name"
                label="Accounts"
                :multiple="true"
                :class="[isAccAlert ? '' : 'errorBox']"
                :fancy="true"
                :options="sortedAccounts"
                :close-on-select="false"
                :value="selectedAccounts"
                @input="onAccountSelect"
              />
            </div>
            <div class="form-group lg-control-box">
              <label for="applications">Applications <span class="required">*</span></label>
              <k-select
                ref="userAppInput"
                name="applications"
                label-key="name"
                label="Applications"
                :multiple="true"
                :class="[isAppAlert ? '' : 'errorBox']"
                :fancy="true"
                :options="filteredAllowedApplications"
                :close-on-select="false"
                :disabled="disableApplicationDrown"
                :value="userApplicationValues"
                @input="onSelectApplication"
              />
            </div>
            <div v-if="showInvite" class="form-group form-check">
              <label class="form-check-label" for="inviteUser">Invite this user?</label>
              <k-checkbox
                :checked="inviteUser"
                name="inviteUser"
                @change="setInviteUser"
                @selected="setInviteUser"
              />
            </div>
            <div class="form-group" style="text-align: right; float:right; display: contents;">
              <div v-if="!savingUser" class="actions-btn">
                <k-button :size="3" label="SAVE" class="mr-20" @click="handleUser" />
                <k-button :size="3" label="CLOSE" @click="close(false)" />
              </div>
              <b-loading-spinner v-else class="txt-center" />
            </div>
          </form>
        </div>
        <div v-if="initialUser && initialUser.id && initialUser.provider !== 'GOOGLE'">
          <div class="modal-body user-form">
            <p style="width: 20%; margin-bottom: 3px" class="com-title"></p>
            <p style="width: 80%; margin-bottom: 3px" class="com-title">ADMIN ACTIONS</p>
          </div>
          <div class="modal-body user-form">
            <p style="width: 20%" class="com-title"></p>
            <p
              style="width: 80%; border-top: 1px solid var(--adminprimarycolor);"
              class="com-title"
            ></p>
          </div>
          <div style="display: flex;" class="modal-body user-form">
            <p style="display: inline-block; width: 20%"></p>
            <p style="display: inline-block; width: 20%"></p>
            <p class="urlMessage">{{ pwResetUrlMessage }}</p>
          </div>
          <div style="display: flex" class="form-group">
            <label>Get Link</label>
            <k-button
              class="adminBtn"
              :size="3"
              label="PASSWORD RESET LINK"
              @click="generatePwReset"
            />
            <textarea
              id="adminBtnPwReset"
              v-model="pwResetUrlText"
              style="width: 60%; display: inline-block; margin-left: 10px; height: auto;"
              type="text"
              readonly="true"
            />
          </div>
        </div>
      </div>
    </div>
  </b-flip-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import { isEqual } from 'underscore';
import KSelect from '@/adready-vue/components/elements/k-select.vue';
import usersApi, { userRolesApi } from 'adready-api/api/user';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import forklift from '~/components/mixins/forklift-mixin';
import authzMixin from '~/components/mixins/authz-mixin';
import * as CONSTANT from '~/constant';

export default {
  components: {
    BFlipModal: () =>
      import(/* webpackChunkName: "b-flip-modal" */ '~/components/elements/b-flip-modal.vue'),
    KSelect,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  mixins: [forklift, authzMixin],
  props: {
    initialUser: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
    allRoles: {
      required: true,
      default() {
        return [];
      },
      type: Array,
    },
    selectedUserAccounts: {
      required: true,
      default() {
        return [];
      },
      type: Array,
    },
    allOrganizations: {
      required: true,
      default() {
        return [];
      },
      type: Array,
    },
  },
  data() {
    const userData = JSON.parse(JSON.stringify(this.initialUser));
    const isNewUser = userData && isBlank(userData.id);
    if (isNewUser && isBlank(userData.provider)) {
      userData.provider = 'LOCAL';
    }
    let roleId = null;
    let changedRoleId = '';
    let roleOptions = [];
    const filteredAllowedApplications = [];
    if (this.initialUser.role) {
      roleId = this.initialUser.role.id;
      changedRoleId = this.initialUser.role.id;
      const orgObj = this.allOrganizations.filter(
        (org) => this.initialUser.organizationId === org.id
      );

      if (orgObj.length > 0) {
        const { orgType } = orgObj[0];
        const allowedRoles = this.getAllowedRoles(orgType);
        roleOptions = this.allRoles.filter((r) => allowedRoles.includes(r.name)) || [];
      } else {
        // Handle the case when orgObj is empty (undefined)
        console.error('Organization not found for user.');
      }
    }
    if (this?.selectedUserAccounts) {
      userData.accountIds = this.selectedUserAccounts.map((item) => item.id);
    }
    return {
      CONSTANT,
      userData,
      roleId,
      changedRoleId,
      errorMsg: null,
      inviteUser: true,
      isRoleAlert: true,
      isAppAlert: true,
      isAccAlert: true,
      isOrgAlert: true,
      savingUser: false,
      roleOptions,
      filteredAllowedApplications,
      inviteUrlMessage: '',
      pwResetUrlText: '',
      pwResetUrlMessage: '',
      selectedAccounts: this.selectedUserAccounts,
      disableApplicationDrown: false,
      selectedOrgType: null,
      selectedAccountOrgIds: [],
      isNewUser,
    };
  },
  computed: {
    allUsers: get('common/allUsers'),
    allApplications: get('common/allApplications'),
    currentUser: get('common/currentUser'),
    allAccounts: get('common/accounts'),
    userAccounts: get('common/userAccounts'),
    whitelabelDomains: get('common/whitelabelDomains'),
    /**
     * for Internal Admin return all the accounts other wise return user's accounts.
     */

    allUserAccounts() {
      if (this.isInternalAdmin) {
        return this.allAccounts;
      }
      if (this.isExternalAdmin) {
        return this.userAccounts;
      }
      return this.accountIds;
    },

    sortedAccounts() {
      const accounts = this.allUserAccounts;
      if (isBlank(accounts)) {
        return [];
      }

      const sortedAccounts = commonHelper.caseInsensitiveSort([].concat(accounts), 'name');

      const activeAccounts = sortedAccounts.filter((u) => !isBlank(u.name) && u.active);
      return activeAccounts;
    },
    userApplicationValues() {
      if (!isBlank(this?.userData?.userApplications)) {
        return this.allApplications.filter((a) =>
          this.userData.userApplications.some((item) => item.applicationId === a.id)
        );
      }
      return [];
    },
    title() {
      return this.existingUser ? 'Edit User' : 'New User';
    },
    DisplayAccountTitle() {
      if (this.changedRoleId === 9 || this.changedRoleId === 10 || this.changedRoleId === 11) {
        return 'Account';
      }
      return 'Managed by Accounts';
    },
    showInvite() {
      if (!this.existingUser) {
        return true;
      }
      return this.localProvider && !this.userData.enabled;
    },
    existingUser() {
      if (this.initialUser.id) {
        return true;
      }
      return false;
    },
    localProvider() {
      return this.userData.provider === 'LOCAL';
    },
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
  },
  watch: {
    allApplications: {
      handler(newVal) {
        this.filteredAllowedApplications = newVal.filter((a) => a.id === 5 || a.id === 6);
      },
      immediate: true, // Run immediately on component mount
    },
  },
  created() {
    this.roleOptions = this.isInternalAdmin
      ? this.allRoles.filter(
          (r) =>
            r.name === 'INTERNAL_ADMIN' ||
            r.name === 'INTERNAL_SUB_ADMIN' ||
            r.name === 'INTERNAL_USER' ||
            r.name === 'EXTERNAL_ADMIN' ||
            r.name === 'EXTERNAL_SUB_ADMIN' ||
            r.name === 'EXTERNAL_USER'
        )
      : this.allRoles.filter(
          (r) =>
            r.name === 'EXTERNAL_ADMIN' ||
            r.name === 'EXTERNAL_SUB_ADMIN' ||
            r.name === 'EXTERNAL_USER'
        );
  },

  mounted() {
    this.loadAllUsers();
    this.loadApplications();
    this.loadWhitelabelDomains();
  },
  methods: {
    async generateInvite() {
      if (!this.initialUser || !this.initialUser.id) {
        this.urlMessage = 'User not understood';
        return;
      }
      try {
        const resp = await usersApi.inviteUrl(this.initialUser.id);
        this.copyToClipboard(resp, 'Invite URL copied', null);
      } catch (error) {
        this.copyToClipboard(null, null, `Something went wrong: ${error.message}`);
      }
    },
    async generatePwReset() {
      try {
        const resp = await usersApi.passwordResetUrl(this.initialUser.id, null, null);
        console.log(`${JSON.stringify(resp)}`);
        this.copyToClipboard(resp, null);
      } catch (error) {
        console.log(`${JSON.stringify(error)}`);
        this.copyToClipboard(null, error.result);
      }
    },
    copyToClipboard(url, failMsg) {
      if (!failMsg || failMsg === null) {
        this.pwResetUrlText = url;
        navigator.clipboard.writeText(url);
        this.pwResetUrlMessage = 'Copied to clipboard';
        setTimeout(() => {
          this.pwResetUrlMessage = '';
        }, 5000);
      } else {
        this.pwResetUrlMessage = failMsg;
        setTimeout(() => {
          this.urlMessage = '';
        }, 5000);
      }
    },
    onChangeEnable(val) {
      this.userData.enabled = val.checked;
    },
    setInviteUser(val) {
      this.inviteUser = val.checked;
    },
    onSelectRole(roleId) {
      this.changedRoleId = roleId;
      this.isRoleAlert = true;
      this.validateRole();
      if (roleId === 9 || roleId === 10 || roleId === 11) {
        this.filteredAllowedApplications = this.allApplications;
      } else {
        this.filteredAllowedApplications = this.allApplications.filter(
          (a) => a.id === 5 || a.id === 6
        );
        const selectedApp = this.allApplications.filter((a) => a.id === 5);
        this.onSelectApplication(selectedApp);
      }
    },
    onSelectApplication(obj, performValidation = true) {
      if (!this.userData.userApplications) {
        this.userData.userApplications = [];
      }
      const data = this.userData.userApplications.filter((a) =>
        obj.some((s) => a.applicationId === s.id)
      );
      obj.forEach((o) => {
        if (!data.some((s) => s.applicationId === o.id)) {
          data.push({ applicationId: o.id });
        }
      });
      this.userData.userApplications = data;
      if (performValidation) {
        this.validateApplications();
      }
    },
    // Write above similar function for accounts select operation
    onAccountSelect(obj) {
      if (!this.userData.accountIds) {
        this.userData.accountIds = [];
      }
      // Extract the Org Ids from selected Accounts (obj)
      const newSelectedAccountOrgIds = obj.map((o) => o.organizationId);
      this.selectedAccountOrgIds = [...newSelectedAccountOrgIds];
      // Extract the IDs from the selected accounts (obj)
      const selectedIds = obj.map((o) => o.id);
      // Update the userData.accountIds by combining existing accounts and selected accounts
      this.userData.accountIds = [...selectedIds];
    },

    getAllowedRoles(orgType) {
      const allowedRoles =
        orgType === 'dr'
          ? ['INTERNAL_ADMIN', 'INTERNAL_SUB_ADMIN', 'INTERNAL_USER']
          : ['EXTERNAL_USER'];
      if (orgType === 'account') {
        allowedRoles.push('EXTERNAL_SUB_ADMIN');
        allowedRoles.push('EXTERNAL_ADMIN');
      }
      return allowedRoles;
    },
    populateRoleOptions(orgType) {
      const allowedRoles = this.getAllowedRoles(orgType);
      const { canCreateRole } = this.currentUser.role;
      return this.allRoles.filter(
        (r) => canCreateRole.includes(r.name) && allowedRoles.includes(r.name)
      );
    },

    onSelectOrganization(organizationId) {
      this.userData.organizationId = organizationId;
      const isValid = this.validateOrganizations();
      if (isValid) {
        const orgObj = this.allOrganizations.filter((org) => organizationId === org.id);
        this.roleOptions = this.populateRoleOptions(orgObj[0].orgType);
        const roleId =
          organizationId === 1 ? CONSTANT.ROLE_ID_INTERNAL_USER : CONSTANT.ROLE_ID_EXTERNAL_USER;
        this.onSelectRole(roleId);
        this.userData.provider = organizationId === 1 ? 'GOOGLE' : 'LOCAL';
      }
      // TO DO call Account selection
      // this account selection is making dropdown empty when pre-selected valued are coming from parent account
      // below check is to skip default account selection
      if (this.selectedUserAccounts.length > 0) {
        const accountSelected = this.sortedAccounts.filter(
          (a) => a.organizationId === organizationId
        );
        this.selectedAccounts = accountSelected;
        this.onAccountSelect(accountSelected);
        //  TO DO call this functinon for edit user. Currently external user are mapped to flib and app both.

        const orgObj = this.allOrganizations.filter((org) => organizationId === org.id);
        const { orgType } = orgObj[0];
        this.selectedOrgType = orgType;
      }
    },
    async handleUser() {
      const dataValidated = this.validateData();
      if (!dataValidated) {
        return;
      }
      if (this.userData?.role?.id !== this.changedRoleId) {
        const selectedRoleOption = this.roleOptions.filter(
          (role) => this.changedRoleId === role.id
        );
        const [selectedRole] = selectedRoleOption;
        this.userData.role = selectedRole;
      }
      const isUnchangedUserData = isEqual(
        this.userData,
        JSON.parse(JSON.stringify(this.initialUser))
      );
      if (isUnchangedUserData) {
        this.close(false);
        return;
      }
      try {
        this.savingUser = true;
        if (!this.existingUser) {
          await this.addUser();
        } else {
          await this.updateUser();
        }
      } catch (err) {
        console.error('error handling user ->', err);
      } finally {
        this.savingUser = false;
        if (isBlank(this.errorMsg)) {
          this.close(true);
        }
      }
    },
    clearFormErrors() {
      this.isAlert = true;
      this.isRoleAlert = true;
      this.isAppAlert = true;
      this.isOrgAlert = true;
      this.errorMsg = null;
      this.isAccAlert = true;
      this.$refs.useremailInput.classList.remove('errorBox');
    },
    validateEmail() {
      this.clearFormErrors();
      if (isBlank(this.userData.email)) {
        this.errorMsg = 'Please enter email id';
        this.$refs.useremailInput.focus();
        this.$refs.useremailInput.classList.add('errorBox');
        return false;
      }
      if (
        !this.userData.email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        this.errorMsg = 'Please enter valid email id';
        this.$refs.useremailInput.focus();
        this.$refs.useremailInput.classList.add('errorBox');
        return false;
      }

      // If this is an existing user then don't perform the next validation
      if (this.existingUser) {
        return true;
      }

      const userFoundWithSameEmail =
        !isBlank(this.allUsers) &&
        this.allUsers.some(
          (u) =>
            !isBlank(u.email) &&
            !isBlank(this.userData.email) &&
            u.email.toUpperCase() === this.userData.email.toUpperCase()
        );
      if (userFoundWithSameEmail) {
        this.errorMsg = `User already exists with ${this.userData.email}`;
        this.$refs.useremailInput.focus();
        this.$refs.useremailInput.classList.add('errorBox');
        return false;
      }

      return true;
    },
    validateApplications() {
      this.clearFormErrors();
      if (isBlank(this.userData.userApplications)) {
        this.errorMsg = 'Please select at least 1 application';
        this.isAppAlert = !this.isAppAlert;
        return false;
      }
      return true;
    },
    validateSelectedAccounts() {
      this.clearFormErrors();
      if (this.DisplayAccountTitle === 'Account') {
        return true;
      }
      if (isBlank(this.userData.accountIds)) {
        this.errorMsg = 'Please select at least 1 account';
        this.isAccAlert = !this.isAccAlert;
        return false;
      }

      return true;
    },
    validateRole() {
      this.clearFormErrors();
      if (isBlank(this.changedRoleId)) {
        this.errorMsg = 'Please select role';
        this.isRoleAlert = !this.isRoleAlert;
        return false;
      }
      return true;
    },
    validateOrganizations(force = true) {
      if (!force) return true;
      this.clearFormErrors();
      const oldOrganizationId = this.initialUser.organizationId;
      const newOrganizationId = this.userData.organizationId;
      // Check if organization was changed
      if (oldOrganizationId !== null && oldOrganizationId !== newOrganizationId) {
        this.errorMsg = `Organization can't be changed`;
        this.isOrgAlert = !this.isOrgAlert;
        return false;
      }
      if (isBlank(this.userData.organizationId)) {
        this.errorMsg = 'Please select an organization';
        this.isOrgAlert = !this.isOrgAlert;
        return false;
      }
      return true;
    },
    // validatePassword() {
    //   this.clearFormErrors();
    //   if (
    //     this.localProvider &&
    //     !this.existingUser &&
    //     !this.inviteUser &&
    //     isBlank(this.userData.password)
    //   ) {
    //     this.errorMsg = 'Please enter password';
    //     this.$refs.passwordInput.focus();
    //     this.$refs.passwordInput.classList.add('errorBox');
    //     return false;
    //   }
    //   return true;
    // },
    validateData() {
      this.clearFormErrors();

      if (
        !this.validateEmail() ||
        !this.validateOrganizations(this.isInternalAdmin) ||
        !this.validateRole() ||
        !this.validateApplications() ||
        !this.validateSelectedAccounts()
        // !this.validatePassword()
      ) {
        return false;
      }

      this.errorMsg = null;
      return true;
    },
    async sendInvite(user) {
      try {
        await usersApi.sendInvite(user.id);
      } catch (err) {
        console.error(`error sending invite to ${user.email} (id: ${user.id}) user ->`, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async addUser() {
      if (this.userData && this.userData.userName) {
        this.userData.userName = this.userData.userName.trim();
      }
      const user = JSON.parse(JSON.stringify(this.userData));
      if (user && user.userName) {
        user.userName = user.userName.trim();
      }
      try {
        if (this.inviteUser) {
          user.enabled = false;
        }
        const createdUser = await usersApi.create(user);
        if (createdUser && createdUser.id) {
          this.userData.id = createdUser.id;
        }
        await userRolesApi.createGlobalRoles([createdUser.id, this.changedRoleId], {
          userId: createdUser.id,
          roleId: this.changedRoleId,
        });
        if (this.inviteUser) {
          await this.sendInvite(createdUser);
        }
        this.loadAllUsers(true);
      } catch (err) {
        this.errorMsg = 'Failed while adding the user';
        console.error('error add user api ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async updateUser() {
      if (this.userData && this.userData.userName) {
        this.userData.userName = this.userData.userName.trim();
      }
      const user = JSON.parse(JSON.stringify(this.userData));
      if (user && user.userName) {
        user.userName = user.userName.trim();
      }
      try {
        await usersApi.update(user.id, user);
        if (this.roleId !== this.changedRoleId) {
          await userRolesApi.createGlobalRoles([user.id, this.changedRoleId], {
            userId: user.id,
            roleId: this.changedRoleId,
          });
        }
        if (this.inviteUser) {
          await this.sendInvite(user);
        }
        this.loadAllUsers(true);
      } catch (err) {
        this.errorMsg = 'Failed while updating the user';
        console.error('error update user api ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    getDefaultSelectedValue() {
      if (this.existingUser && this.initialUser.userApplications) {
        return this.allApplications.filter((a) =>
          this.initialUser.userApplications.some((o) => a.id === o.applicationId)
        );
      }
      return [];
    },
    close(isSaved) {
      if (isSaved) {
        this.$emit('close', this.userData ? this.userData.id : null);
      } else {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}
/**custom */
.list-box {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: #212429;
  border-radius: 0;
  padding: 30px 20px;
  width: 100%;
  display: block;
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
  ul {
    margin-bottom: 10px;
    ul {
      border: 0;
      margin: 0;
    }
    li {
      font-size: 12px;
      line-height: 0.75rem;
      color: rgba(130, 130, 140, 0.8);
      padding-bottom: 5px;
      padding-top: 5px;
      margin-bottom: 0;
      position: relative;

      li {
        border-bottom: 1px solid rgba(170, 170, 170, 0.1);
        padding: 15px 15px 15px 10px;
        list-style: none;
        list-style-position: inside;
        font-size: 13px;
        a {
          color: #848890;
        }
        .box {
          svg {
            color: #848890;
            &:hover {
              color: var(--adminprimarycolor) !important;
            }
          }
        }
      }
      a {
        color: #cad1d6;
        display: inline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .button-box {
    position: absolute;
    top: 5px;
    right: 0;
    line-height: 30px;
    .box {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);
  .modal-header {
    margin-bottom: 10px;
    h2 {
      text-align: center;
      color: #fff;
      line-height: 1.3;
      padding-bottom: 10px;
    }
    p {
      border-top: 2px solid white;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      margin: 0;
      color: #fff;
    }
  }
  .modal-body {
    width: 600px;
  }
  .form-group {
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    color: #fff;
    .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #cad1d6;
      border: none;
      background-color: var(--adminprimarycolor);
      font-size: 16px;
      padding: 14px;
      border-radius: 0 4px 4px 0;
      line-height: 12px;
    }
    .list-url {
      clear: both;
      width: 75%;
      line-height: 25px;
      float: right;
      font-size: 13px;
      margin-top: -6px;
      min-height: 15px;
      color: #848890;
      margin-left: 120px;
      p {
        text-align: left;
      }
      a {
        display: inline;
        color: #848890;
      }
      span {
        font-size: 10px;
        top: -6px;
        color: #ff0202;
        left: 3px;
        position: relative;
      }
    }
    label {
      width: 20%;
      display: inline-block;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      float: left;
      padding: 9px;
      line-height: 20px;
    }
    input {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    select {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 14px;
      color: #cad1d6;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      text-transform: capitalize;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.1);
      border-radius: 4px;
    }
    input[type='checkbox'] {
      width: auto;
      background-color: #323239;
      color: #cad1d6;
      &::before,
      &::after {
        background-color: #323239;
        color: #cad1d6;
      }
    }
    textarea {
      width: 80%;
      float: left;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    button {
      padding: 5px;
      font-size: 16px;
      margin-left: 10px;
      float: right;
    }
  }
}
.top-li {
  .button-box {
    top: 0;
  }
  a {
    svg {
      font-size: 19px;
      color: #ddd;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
.sub-li {
  padding: 10px 10px 10px 0;
  margin-top: 15px;
  // height: 30px;
  .button-box {
    top: -2px;
  }
  a {
    font-size: 14px;
    svg {
      font-size: 14px;
      color: #cad1d6;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
a {
  svg {
    color: #fff;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
.com-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}
.actions-btn {
  margin-bottom: 20px;
}
::v-deep .checkbox-btn {
  margin-left: 122px;
  height: 40px;
  span {
    font-size: 14px;
    color: #cad1d6;
    letter-spacing: 0.07em;
  }
  svg {
    width: 18px;
    height: 18px;
    &.checkbox-inactive {
      color: #cad1d6;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      background-color: #323239;
      path {
        fill: #323239;
      }
    }
  }
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.line-hr {
}
.sm {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: 1px solid var(--adminprimarycolor);
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: left;
  color: #fff;
  margin-top: -20px;
}
tr.no-bg {
  line-height: 80px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.mg-tp-20 {
  margin-top: 20px;
}
tr.no-bg-no-pg {
  line-height: 40px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 0 0 8px;
  }
}
td a {
  display: inline-block;
  margin-right: 8px;
}
.col-lh-40 {
  line-height: 40px;
  display: inline;
}
tr.no-bg-axnbtn {
  overflow: hidden;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}

.lg-control-box {
  display: inline-flex;
  width: 100%;
  label {
    width: 25% !important;
  }
}
::v-deep .form-input-wrap {
  height: 42px;
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;
  .form-click {
    border: none !important;
  }
}

::v-deep .modal-body .form-input .form-input-field.v-select {
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #323239 !important;
  min-height: 38px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__search {
  padding: 4px 6px;
  color: #cad1d6;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  color: #cad1d6;
  font-size: 14px;
}
::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .row.action-icons {
  margin-left: -0.9rem !important;
}
::v-deep .form-input-wrap .form-click .action-icons svg,
::v-deep .form-input-wrap .form-click .selected-text {
  font-size: 14px;
}
::v-deep
  .form-input
  .form-input-field.v-select.vs--open
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  transform: rotate(-180deg);
  font-size: 14px;
}

::v-deep .dropdown .dropdown-menu {
  position: initial !important;
}
::v-deep .modal-body .form-click {
  // border: 1px solid rgba(170, 170, 170, 0.1) !important;
  padding: 0;
  margin: 0;
  &.disabled {
    cursor: not-allowed;
  }
}
::v-deep .form-input .form-input-field.v-select .vs__selected-options {
  padding: 0 6px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  padding: 0 6px !important;
  margin: 0 4px !important;
}
.vs__selected {
  color: #01abb7 !important;
  background-color: rgba(78, 78, 78, 0.4) !important;
}
::v-deep .form-input-wrap .form-click .selected-text {
  padding: 0 0 0 14px !important;
}

::v-deep .form-input-wrap .form-click .action-icons {
  padding: 0 0 0 0 !important;
}

::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__actions {
  padding: 2px 15px 0 0 !important;
}
::v-deep .form-group.dd-options {
  overflow: unset !important;
  .dropdown {
    .dropdown-menu {
      position: absolute !important;
    }
  }
}
::v-deep .user-form .dropdown {
  width: 80%;
  margin-right: 0 !important;
}

::v-deep .form-input-wrap .form-click .action-icons svg {
  position: relative;
  left: -8px;
}

::v-deep .txt-center {
  width: auto !important;
}
.urlMessage {
  display: inline-block;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 600;
  width: 60%;
  text-align: left;
  color: var(--adminprimarycolor);
}
.adminBtn {
  display: inline-block;
  width: 20%;
  //max-width: 115px;
  line-height: 0.8em;
  //padding: 6px 19px;
}
p {
  border-bottom: none !important;
}
</style>
